<template>
  <div
    class="top-50 start-50 translate-middle spinner_container"
    v-if="is_loading"
  >
    <div
      class="spinner-border text-primary"
      style="width: 4rem; height: 4rem"
      role="status"
    >
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"
export default {
  name: "TheLoadingSpinner",
  computed: {
    ...mapState("status", ["is_loading"])
  }
}
</script>

<style>
.spinner_container {
  position: absolute;
  z-index: 99;
}
</style>
