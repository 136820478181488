import axios from "axios"
import {
  UPDATE_STATUS,
  UPDATE_SPINNER,
  ADD_TRACK,
  ADD_TRACKS,
  EDIT_TRACK,
  DELETE_TRACK,
  SET_TRACK,
  ADD_PLAYLIST_TRACK,
  EDIT_PLAYLIST_TRACK,
  DELETE_PLAYLIST_TRACK
} from "../utils/mutation-types"
import { errorStatus } from "../utils/misc"

export default {
  namespaced: true,

  state: {
    tracks: [],
    track: null
  },

  actions: {
    async getTracks({ commit }) {
      try {
        commit(`status/${UPDATE_SPINNER}`, { is_loading: true }, { root: true })
        const response = await axios.get(`/api/tracks`)
        commit(ADD_TRACKS, response.data.data)
        commit(
          `status/${UPDATE_SPINNER}`,
          { is_loading: false },
          { root: true }
        )
      } catch (error) {
        commit(
          `status/${UPDATE_SPINNER}`,
          { is_loading: false },
          { root: true }
        )
        const status = errorStatus(error)
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      }
    },
    async createTrack({ commit }, payload) {
      try {
        commit(`status/${UPDATE_SPINNER}`, { is_loading: true }, { root: true })
        const response = await axios.post(`/api/tracks`, payload)
        const status = {
          message: response.data.message,
          type: "success"
        }
        commit(
          `status/${UPDATE_SPINNER}`,
          { is_loading: false },
          { root: true }
        )
        const track = response.data.track
        commit(ADD_TRACK, track)
        commit(`playlist/${ADD_PLAYLIST_TRACK}`, track, { root: true })
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      } catch (error) {
        commit(
          `status/${UPDATE_SPINNER}`,
          { is_loading: false },
          { root: true }
        )
        const status = errorStatus(error)
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      }
    },
    async editTrack({ commit }, payload) {
      try {
        commit(`status/${UPDATE_SPINNER}`, { is_loading: true }, { root: true })
        const response = await axios.put(
          `/api/tracks/${payload.get("id")}`,
          payload
        )
        const status = {
          message: response.data.message,
          type: "success"
        }
        commit(
          `status/${UPDATE_SPINNER}`,
          { is_loading: false },
          { root: true }
        )
        const track = response.data.track
        commit(EDIT_TRACK, track)
        commit(`playlist/${EDIT_PLAYLIST_TRACK}`, track, { root: true })
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      } catch (error) {
        commit(
          `status/${UPDATE_SPINNER}`,
          { is_loading: false },
          { root: true }
        )
        const status = errorStatus(error)
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      }
    },
    async deleteTrack({ commit }, payload) {
      try {
        commit(`status/${UPDATE_SPINNER}`, { is_loading: true }, { root: true })
        const response = await axios.delete(`/api/tracks/${payload.track_id}`)
        const status = {
          message: response.data.message,
          type: "success"
        }
        commit(
          `status/${UPDATE_SPINNER}`,
          { is_loading: false },
          { root: true }
        )
        commit(DELETE_TRACK, payload)
        commit(SET_TRACK, null)
        commit(`playlist/${DELETE_PLAYLIST_TRACK}`, payload, { root: true })
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      } catch (error) {
        commit(
          `status/${UPDATE_SPINNER}`,
          { is_loading: false },
          { root: true }
        )
        const status = errorStatus(error)
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      }
    },
    async viewTrack({ commit }, payload) {
      try {
        const response = await axios.get(`/api/tracks/view/${payload.id}`)
        commit(EDIT_TRACK, response.data)
      } catch (error) {
        const status = errorStatus(error)
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      }
    }
  },

  mutations: {
    [SET_TRACK](state, payload) {
      state.track = payload
    },
    [ADD_TRACK](state, payload) {
      state.tracks.unshift(payload)
    },
    [EDIT_TRACK](state, payload) {
      state.tracks = state.tracks.map((track) => {
        if (track.id === payload.id) {
          track = payload
        }
        return track
      })
    },
    [DELETE_TRACK](state, payload) {
      const trackIndex = state.tracks.findIndex(
        (track) => track.id === payload.id
      )
      if (trackIndex !== -1) {
        state.tracks.splice(trackIndex, 1)
      }
    },
    [ADD_TRACKS](state, payload) {
      state.tracks = payload
    }
  }
}
