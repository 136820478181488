<template>
  <div>
    <!-- top bar -->
    <nav class="navbar-light bg-white shadow-sm py-1 fixed-top">
      <div class="container px-0">
        <div class="d-flex pb-2 px-3" style="border-bottom: 1px solid #c4c4c4">
          <router-link to="/" class="me-2 bg-white text-decoration-none">
            <img
              :src="require('@/assets/img/logo-mobile.svg')"
              alt="logo"
              class="logo navbar-brand text-primary"
            />
          </router-link>
          <div
            class="d-flex align-items-center justify-content-center"
            v-if="isAuthenticated"
          >
            <search-box @result-selected="handleResultSelected"></search-box>
          </div>
        </div>
        <!-- bottom bar -->
        <div class="navbar navbar-expand-md navbar-light bg-white py-2">
          <div
            class="d-flex justify-content-center px-4 pb-2 w-100 flex-column"
            v-if="isAuthenticated && showSettings"
          >
            <router-link
              class="dropdown-item my-2 bg-secondary bg-gradient text-white"
              to="/legal"
            >
              Privacy Policies
            </router-link>
            <router-link
              class="dropdown-item mb-2 bg-secondary bg-gradient text-white"
              to="/dashboard/posts"
              v-if="isAdmin"
            >
              Admin Dashboard
            </router-link>
            <div
              class="dropdown-item bg-primary bg-gradient text-white"
              style="cursor: pointer"
              @click="logout"
            >
              Log Out
            </div>
          </div>

          <div
            class="container d-flex align-items-center justify-content-between"
          >
            <router-link to="/" class="nav-link-icons mx-2">
              <i class="bi bi-house-door-fill fs-5"></i>
            </router-link>
            <router-link to="/playlists" class="nav-link-icons mx-2">
              <i class="bi bi-file-music fs-5"></i>
            </router-link>
            <router-link
              to="/lostdocuments"
              class="nav-link-icons mx-2"
              v-if="isAuthenticated"
            >
              <i class="bi bi-file-earmark-richtext-fill fs-5"></i>
            </router-link>
            <router-link
              to="/message"
              class="nav-link-icons mx-2"
              v-if="isAuthenticated"
            >
              <i class="bi bi-bell-fill fs-5"></i>
            </router-link>
            <router-link
              to="/settings"
              class="nav-link-icons mx-2"
              v-if="isAuthenticated"
            >
              <i class="bi bi-list fs-5"></i>
            </router-link>
            <div v-if="!isAuthenticated">
              <router-link
                to="/login"
                class="btn btn-primary rounded-pill px-4 mx-2 text-white"
              >
                Login
              </router-link>
              <router-link
                to="/signup"
                class="btn btn-dark rounded-pill px-4 mx-2"
              >
                Sign Up
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <div
      class="d-flex justify-content-center justify-content-lg-end align-items-lg-end user-count"
    >
      <div
        class="alert alert-primary alert-dismissible fade show sticky-top"
        role="alert"
      >
        <strong>Join 5.8M</strong> other users daily on Propelond worldwide.
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="alert"
          aria-label="Close"
        ></button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex"
import { isAuth } from "@/utils/misc"
import { mapState } from "vuex"
import SearchBox from "./SearchBox.vue"
export default {
  name: "TheMobileNav",
  components: {
    SearchBox
  },
  data() {
    return {
      showSettings: false
    }
  },
  methods: {
    ...mapActions("auth", ["logout"]),
    handleResultSelected(result) {
      console.log("Selected result:", result)
    }
  },
  computed: {
    ...mapState("user", ["user"]),
    isAuthenticated() {
      return isAuth()
    },
    isAdmin() {
      return this.user?.type === "admin"
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/auth";
.nav {
  z-index: 1;
}
.search-box {
  background-color: #fff;
  border: 1px solid #eaedf2;
  border-radius: 55px;
  width: 17rem;
  font-size: 0.8rem;
  outline: none;
}
.nav-link-icons {
  background: #eaedf2;
  border-radius: 100px;
  padding: 0.5rem 0.8rem;
  color: black;
}
/* .nav-link-icons:hover{
    background-color: #D2E3FF;
} */
.nav-link-icons:focus {
  background: #eaedf2;
}
.active-link {
  background: #eaedf2;
  color: #ff0bb9;
}
.profile-img {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 100px;
}
.logo {
  width: 2.8rem;
}
.user-count {
  margin-bottom: -4.5rem;
  z-index: 2;
}
</style>
