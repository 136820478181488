import { createApp } from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import "intersection-observer"
import "./assets/scss/index.scss"
import "bootstrap-icons/font/bootstrap-icons.css"
import "bootstrap"
import { VueCookieNext } from "vue-cookie-next"
import axios from "./plugins/axios"
import fontAwesome from "./plugins/font-awesome"

import VueObserveVisibility from "vue3-observe-visibility"
import linkify from "vue-linkify"
import cloudinary from "./plugins/cloudinary"
import VueEasyLightbox from "vue-easy-lightbox"
import "cloudinary-video-player/dist/cld-video-player.min.css"

const app = createApp(App).use(store).use(router)

app.use(VueObserveVisibility)
app.use(axios)

app.use(VueCookieNext, {
  expireTimes: "30d"
})
app.use(fontAwesome)
app.use(cloudinary)

app.directive("linkified", linkify)
app.use(VueEasyLightbox)
app.mount("#app")
