import axios from "axios"
import {
  UPDATE_STATUS,
  UPDATE_SPINNER,
  ADD_FOLLOWERS,
  ADD_USER_FOLLOWERS,
  ADD_FOLLOWING,
  ADD_USER_FOLLOWING,
  ADD_FOLLOW,
  DELETE_FOLLOW,
  ADD_USER_FOLLOW,
  DELETE_USER_FOLLOW
} from "../utils/mutation-types"
import { errorStatus } from "../utils/misc"

export default {
  namespaced: true,

  state: {
    following: [],
    followers: [],
    user_following: [],
    user_followers: []
  },

  actions: {
    async getFollowers({ commit }) {
      try {
        commit(`status/${UPDATE_SPINNER}`, { is_loading: true }, { root: true })
        const response = await axios.get(`/api/followers`)
        commit(ADD_FOLLOWERS, response.data.followers)
        commit(
          `status/${UPDATE_SPINNER}`,
          { is_loading: false },
          { root: true }
        )
      } catch (error) {
        commit(
          `status/${UPDATE_SPINNER}`,
          { is_loading: false },
          { root: true }
        )
        const status = errorStatus(error)
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      }
    },
    async getUserFollowers({ commit }, userId) {
      try {
        commit(`status/${UPDATE_SPINNER}`, { is_loading: true }, { root: true })
        const response = await axios.get(`/api/followers/users/${userId}`)
        commit(ADD_USER_FOLLOWERS, response.data.followers)
        commit(
          `status/${UPDATE_SPINNER}`,
          { is_loading: false },
          { root: true }
        )
      } catch (error) {
        commit(
          `status/${UPDATE_SPINNER}`,
          { is_loading: false },
          { root: true }
        )
        const status = errorStatus(error)
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      }
    },
    async getFollowing({ commit }) {
      try {
        commit(`status/${UPDATE_SPINNER}`, { is_loading: true }, { root: true })
        const response = await axios.get(`/api/following`)
        commit(ADD_FOLLOWING, response.data.following)
        commit(
          `status/${UPDATE_SPINNER}`,
          { is_loading: false },
          { root: true }
        )
      } catch (error) {
        commit(
          `status/${UPDATE_SPINNER}`,
          { is_loading: false },
          { root: true }
        )
        const status = errorStatus(error)
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      }
    },
    async getUserFollowing({ commit }, userId) {
      try {
        commit(`status/${UPDATE_SPINNER}`, { is_loading: true }, { root: true })
        const response = await axios.get(`/api/following/users/${userId}`)
        commit(ADD_USER_FOLLOWING, response.data.following)
        commit(
          `status/${UPDATE_SPINNER}`,
          { is_loading: false },
          { root: true }
        )
      } catch (error) {
        commit(
          `status/${UPDATE_SPINNER}`,
          { is_loading: false },
          { root: true }
        )
        const status = errorStatus(error)
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      }
    },
    async follow({ commit, rootState }, payload) {
      try {
        commit(`status/${UPDATE_SPINNER}`, { is_loading: true }, { root: true })
        const response = await axios.get(`/api/follow/${payload.friend_id}`)
        commit(
          `status/${UPDATE_SPINNER}`,
          { is_loading: false },
          { root: true }
        )
        const status = {
          message: response.data.message,
          type: "success"
        }
        commit(ADD_FOLLOW, response.data.friend_request)
        if (rootState.user.user.id === rootState.user.profile?.id) {
          commit(ADD_USER_FOLLOW, response.data.friend_request)
        }
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      } catch (error) {
        commit(
          `status/${UPDATE_SPINNER}`,
          { is_loading: false },
          { root: true }
        )
        const status = errorStatus(error)
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      }
    },
    async unfollow({ commit, rootState }, payload) {
      try {
        commit(`status/${UPDATE_SPINNER}`, { is_loading: true }, { root: true })
        const response = await axios.delete(
          `/api/unfollow/${payload.friend_id}`
        )
        commit(
          `status/${UPDATE_SPINNER}`,
          { is_loading: false },
          { root: true }
        )
        const status = {
          message: response.data.message,
          type: "success"
        }
        commit(DELETE_FOLLOW, payload)
        if (rootState.user.user.id === rootState.user.profile?.id) {
          commit(DELETE_USER_FOLLOW, payload)
        }
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      } catch (error) {
        commit(
          `status/${UPDATE_SPINNER}`,
          { is_loading: false },
          { root: true }
        )
        const status = errorStatus(error)
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      }
    }
  },

  mutations: {
    [ADD_FOLLOWERS](state, payload) {
      state.followers = payload
    },
    [ADD_FOLLOWING](state, payload) {
      state.following = payload
    },
    [ADD_USER_FOLLOWERS](state, payload) {
      state.user_followers = payload
    },
    [ADD_USER_FOLLOWING](state, payload) {
      state.user_following = payload
    },
    [ADD_FOLLOW](state, payload) {
      state.following.unshift(payload)

      // Set status to 0 if present in followers
      state.followers = state.followers.map((follower) => {
        if (follower.user_id === payload.friend_id) {
          follower.status = 1
        }
        return follower
      })
    },
    [DELETE_FOLLOW](state, payload) {
      const followIndex = state.following.findIndex(
        (follow) => follow.friend_id === payload.friend_id
      )
      if (followIndex !== -1) {
        state.following.splice(followIndex, 1)
      }

      // Set status to 0 if present in followers
      state.followers = state.followers.map((follower) => {
        if (follower.user_id === payload.friend_id) {
          follower.status = 0
        }
        return follower
      })
    },
    [ADD_USER_FOLLOW](state, payload) {
      state.user_following.unshift(payload)

      // Set status to 0 if present in followers
      state.user_followers = state.user_followers.map((follower) => {
        if (follower.user_id === payload.friend_id) {
          follower.status = 1
        }
        return follower
      })
    },
    [DELETE_USER_FOLLOW](state, payload) {
      const followIndex = state.user_following.findIndex(
        (follow) => follow.friend_id === payload.friend_id
      )
      if (followIndex !== -1) {
        state.user_following.splice(followIndex, 1)
      }

      // Set status to 0 if present in followers
      state.user_followers = state.user_followers.map((follower) => {
        if (follower.user_id === payload.friend_id) {
          follower.status = 0
        }
        return follower
      })
    }
  },
  getters: {
    getFollowingById: (state) => (id) => {
      return state.following.find((friend) => friend.friend_id === id)
    },
    getFollowerById: (state) => (id) => {
      return state.followers.find((friend) => friend.user_id === id)
    }
  }
}
