import axios from "axios"
import {
  UPDATE_STATUS,
  UPDATE_SPINNER,
  ADD_LOST_DOCUMENT,
  EDIT_LOST_DOCUMENT,
  ADD_LOST_DOCUMENTS,
  DELETE_LOST_DOCUMENT
} from "../utils/mutation-types"

import { errorStatus } from "../utils/misc"

export default {
  namespaced: true,

  state: {
    lost_documents: [],
    page: {
      current_page: 1,
      last_page: null
    }
  },

  actions: {
    async getLostDocuments({ commit }, payload) {
      try {
        commit(`status/${UPDATE_SPINNER}`, { is_loading: true }, { root: true })
        const response = await axios.get(`/api/lostDocs?page=${payload.page}`)
        commit(ADD_LOST_DOCUMENTS, response.data)
        commit(
          `status/${UPDATE_SPINNER}`,
          { is_loading: false },
          { root: true }
        )
      } catch (error) {
        commit(
          `status/${UPDATE_SPINNER}`,
          { is_loading: false },
          { root: true }
        )
        const status = errorStatus(error)
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      }
    },
    async createLostDocument({ commit }, payload) {
      try {
        commit(`status/${UPDATE_SPINNER}`, { is_loading: true }, { root: true })
        const response = await axios.post(`/api/lostDocs`, payload, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        commit(
          `status/${UPDATE_SPINNER}`,
          { is_loading: false },
          { root: true }
        )
        const status = {
          message: response.data.message,
          type: "success"
        }
        commit(ADD_LOST_DOCUMENT, response.data.LostDocument)
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      } catch (error) {
        commit(
          `status/${UPDATE_SPINNER}`,
          { is_loading: false },
          { root: true }
        )
        const status = errorStatus(error)
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      }
    },
    async editLostDocument({ commit }, payload) {
      commit(`status/${UPDATE_SPINNER}`, { is_loading: true }, { root: true })
      try {
        const response = await axios.post(
          `/api/lostDocs/${payload.get("id")}`,
          payload,
          {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          }
        )
        commit(
          `status/${UPDATE_SPINNER}`,
          { is_loading: false },
          { root: true }
        )
        const status = {
          message: response.data.message,
          type: "success"
        }
        commit(EDIT_LOST_DOCUMENT, response.data.lostDocument)
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      } catch (error) {
        commit(
          `status/${UPDATE_SPINNER}`,
          { is_loading: false },
          { root: true }
        )
        const status = errorStatus(error)
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      }
    },
    async deleteLostDocument({ commit }, payload) {
      try {
        const response = await axios.delete(`/api/lostDocs/${payload.id}`)
        const status = {
          message: response.data.message,
          type: "success"
        }
        commit(DELETE_LOST_DOCUMENT, payload)
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      } catch (error) {
        const status = errorStatus(error)
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      }
    },
    async viewLostDocument({ commit }, payload) {
      try {
        const response = await axios.get(`/api/lostDocs/view/${payload.id}`)
        commit(EDIT_LOST_DOCUMENT, response.data)
      } catch (error) {
        const status = errorStatus(error)
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      }
    }
  },

  mutations: {
    [ADD_LOST_DOCUMENT](state, payload) {
      state.lost_documents.unshift(payload)
    },
    [EDIT_LOST_DOCUMENT](state, payload) {
      state.lost_documents = state.lost_documents.map((lostDocument) => {
        if (lostDocument.id === payload.id) {
          lostDocument = payload
        }
        return lostDocument
      })
    },
    [DELETE_LOST_DOCUMENT](state, payload) {
      const lostDocumentIndex = state.lost_documents.findIndex(
        (lostDocument) => lostDocument.id === payload.id
      )
      if (lostDocumentIndex !== -1) {
        state.lost_documents.splice(lostDocumentIndex, 1)
      }
    },
    [ADD_LOST_DOCUMENTS](state, payload) {
      const current_page = payload.meta.current_page
      if (current_page === 1) {
        state.lost_documents = payload.data
      } else {
        state.lost_documents = state.lost_documents.concat(payload.data)
      }
      state.page = {
        current_page,
        last_page: payload.meta.last_page
      }
    }
  }
}
