import { createStore } from "vuex"
import status from "./status"
import auth from "./auth"
import post from "./post"
import user from "./user"
import comment from "./comment"
import like from "./like"
import lostDocument from "./lost-document"
import follower from "./follower"
import playlist from "./playlist"
import track from "./track"
import photo from "./photo"
import verify from "./verify"

export default createStore({
  modules: {
    status,
    auth,
    post,
    user,
    comment,
    like,
    lostDocument,
    follower,
    playlist,
    track,
    photo,
    verify
  }
})
