import Axios from "axios"
import router from "../router"
import { VueCookieNext } from "vue-cookie-next"

export default {
  install() {
    Axios.interceptors.request.use(
      (config) => {
        config.baseURL = process.env.VUE_APP_API_URL

        if (
          config.url.includes("/api/login") ||
          config.url.includes("/api/register") ||
          (config.url.includes("/api/posts") && config.method == "get")
        ) {
          return config
        }

        const token = VueCookieNext.getCookie("token")
        // if (!token) {
        //   router.push("/login")
        // }

        return {
          ...config,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      },

      (error) => {
        return Promise.reject(error)
      }
    )

    Axios.interceptors.response.use(
      (response) => {
        const {
          data: { message }
        } = response
        if (
          message === "token is expired" ||
          message === "invalid token" ||
          message === "provide token"
        ) {
          router.push("/login")
        }
        return response
      },
      (error) => {
        return Promise.reject(error)
      }
    )
  }
}
