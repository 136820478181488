<template>
  <div class="home">
    <div class="mb-4" v-if="!guestRoutes.includes($route.name)">
      <div class="d-none d-lg-block">
        <the-navbar></the-navbar>
      </div>
      <div class="d-block d-lg-none mb-5">
        <the-mobile-nav></the-mobile-nav>
      </div>
    </div>
  </div>
  <the-notification></the-notification>
  <the-loading-spinner></the-loading-spinner>
  <div class="mt-5">
    <slot />
  </div>
</template>

<script>
import TheNavbar from "@/components/TheNavbar.vue"
import TheMobileNav from "@/components/TheMobileNav"
import TheNotification from "@/components/TheNotification.vue"
import TheLoadingSpinner from "@/components/TheLoadingSpinner.vue"

export default {
  name: "MainLayout",
  components: {
    TheNavbar,
    TheMobileNav,
    TheNotification,
    TheLoadingSpinner
  },
  data() {
    return {
      guestRoutes: ["login", "signup", "about", "resetPassword"]
    }
  }
}
</script>

<style scoped>
.home {
  height: fit-content;
}
</style>
