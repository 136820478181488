// Feedback and progress
export const UPDATE_STATUS = "UPDATE_STATUS"
export const UPDATE_SPINNER = "UPDATE_SPINNER"

// Posts
export const EDIT_POST = "EDIT_POST"
export const ADD_POST = "ADD_POST"
export const ADD_POSTS = "ADD_POSTS"
export const ADD_USER_POSTS = "ADD_USER_POSTS"
export const ADD_ADMIN_POSTS = "ADD_ADMIN_POSTS"
export const DELETE_POST = "DELETE_POST"
export const DELETE_ADMIN_POST = "DELETE_ADMIN_POST"
export const ADD_POST_COMMENT = "ADD_POST_COMMENT"
export const EDIT_POST_COMMENT = "EDIT_POST_COMMENT"
export const DELETE_POST_COMMENT = "DELETE_POST_COMMENT"
export const DELETE_ADMIN_COMMENT = "DELETE_ADMIN_COMMENT"
export const TOGGLE_POST_LIKE = "TOGGLE_POST_LIKE"
export const VIEW_POST = "VIEW_POST"
export const SET_POST_STATS = "SET_POST_STATS"

// Comments
export const EDIT_COMMENT = "EDIT_COMMENT"
export const ADD_COMMENT = "ADD_COMMENT"
export const ADD_COMMENTS = "ADD_COMMENTS"
export const DELETE_COMMENT = "DELETE_COMMENT"

// User
export const ADD_USERS = "ADD_USERS"
export const ADD_VERIFIED_USERS = "ADD_VERIFIED_USERS"
export const DELETE_USER = "DELETE_USER"
export const SET_USER = "SET_USER"
export const SET_USER_STATS = "SET_USER_STATS"
export const SET_PROFILE = "SET_PROFILE"

// Like
export const TOGGLE_LIKE = "TOGGLE_LIKE"

// Lost & Found Document
export const ADD_LOST_DOCUMENT = "POST_LOST_DOCUMENT"
export const EDIT_LOST_DOCUMENT = "EDIT_LOST_DOCUMENT"
export const DELETE_LOST_DOCUMENT = "DELETE_LOST_DOCUMENT"
export const ADD_LOST_DOCUMENTS = "POST_LOST_DOCUMENTS"

// Following and followers
export const ADD_FOLLOW = "ADD_FOLLOW"
export const DELETE_FOLLOW = "DELETE_FOLLOW"
export const ADD_FOLLOWING = "ADD_FOLLOWING"
export const ADD_USER_FOLLOWING = "ADD_USER_FOLLOWING"
export const ADD_FOLLOWERS = "ADD_FOLLOWERS"
export const ADD_USER_FOLLOWERS = "ADD_USER_FOLLOWERS"
export const ADD_USER_FOLLOW = "ADD_USER_FOLLOW"
export const DELETE_USER_FOLLOW = "DELETE_USER_FOLLOW"

// Playlist
export const ADD_PLAYLIST = "ADD_PLAYLIST"
export const EDIT_PLAYLIST = "EDIT_PLAYLIST"
export const DELETE_PLAYLIST = "DELETE_PLAYLIST"
export const SET_PLAYLIST = "SET_PLAYLIST"
export const ADD_PLAYLISTS = "ADD_PLAYLISTS"
export const ADD_USER_PLAYLISTS = "ADD_USER_PLAYLISTS"
export const ADD_PLAYLIST_TRACK = "ADD_PLAYLIST_TRACK"
export const DELETE_PLAYLIST_TRACK = "DELETE_PLAYLIST_TRACK"
export const EDIT_PLAYLIST_TRACK = "EDIT_PLAYLIST_TRACK"
export const ADD_SEARCH_PLAYLISTS = "ADD_SEARCH_PLAYLISTS"
export const ADD_SEARCH_USER_PLAYLISTS = "ADD_SEARCH_USER_PLAYLISTS"

// Track
export const ADD_TRACK = "ADD_TRACK"
export const EDIT_TRACK = "EDIT_TRACK"
export const DELETE_TRACK = "DELETE_TRACK"
export const ADD_TRACKS = "ADD_TRACKS"
export const SET_TRACK = "SET_TRACK"

// Photo
export const ADD_PHOTOS = "ADD_PHOTOS"
export const ADD_PHOTO = "ADD_PHOTO"
export const DELETE_PHOTO = "DELETE_PHOTO"
