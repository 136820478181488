import axios from "axios"
import { VueCookieNext } from "vue-cookie-next"
import router from "../router"
import { UPDATE_STATUS, UPDATE_SPINNER } from "../utils/mutation-types"
import { errorStatus } from "../utils/misc"

export default {
  namespaced: true,

  actions: {
    async login({ commit }, payload) {
      try {
        commit(`status/${UPDATE_SPINNER}`, { is_loading: true }, { root: true })
        await axios.post(`/api/login`, payload).then((res) => {
          VueCookieNext.setCookie("token", res.data.access_token)
          commit(
            `status/${UPDATE_SPINNER}`,
            { is_loading: false },
            { root: true }
          )
          router.push("/")
        })
      } catch (error) {
        commit(
          `status/${UPDATE_SPINNER}`,
          { is_loading: false },
          { root: true }
        )
        const status = errorStatus(error)
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      }
    },
    async signUp({ commit }, payload) {
      try {
        commit(`status/${UPDATE_SPINNER}`, { is_loading: true }, { root: true })
        await axios.post(`/api/register`, payload).then((res) => {
          const token = res.data.token.original.access_token
          VueCookieNext.setCookie("token", token)

          const status = {
            message: res.data.message,
            type: "success"
          }
          commit(`status/${UPDATE_STATUS}`, status, { root: true })
          router.push("/")
        })
      } catch (error) {
        commit(
          `status/${UPDATE_SPINNER}`,
          { is_loading: false },
          { root: true }
        )
        const status = errorStatus(error)
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      }
    },
    async logout({ commit }) {
      try {
        commit(`status/${UPDATE_SPINNER}`, { is_loading: true }, { root: true })
        await axios.post(`/api/logout`, {}).then(() => {
          VueCookieNext.removeCookie("token")
          router.push("/login")
        })
        commit(
          `status/${UPDATE_SPINNER}`,
          { is_loading: false },
          { root: true }
        )
      } catch (error) {
        commit(
          `status/${UPDATE_SPINNER}`,
          { is_loading: false },
          { root: true }
        )
        const status = errorStatus(error)
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      }
    },
    async updatePassword({ commit }, payload) {
      try {
        commit(`status/${UPDATE_SPINNER}`, { is_loading: true }, { root: true })
        const response = await axios.put(`/api/update_password`, payload)
        const status = {
          message: response.data.message,
          type: "success"
        }
        commit(
          `status/${UPDATE_SPINNER}`,
          { is_loading: false },
          { root: true }
        )
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      } catch (error) {
        commit(
          `status/${UPDATE_SPINNER}`,
          { is_loading: false },
          { root: true }
        )
        const status = errorStatus(error)
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      }
    },
    async forgotPassword({ commit }, payload) {
      try {
        commit(`status/${UPDATE_SPINNER}`, { is_loading: true }, { root: true })
        const response = await axios.post(`/api/forgot-password`, payload)
        const status = {
          message: response.data.message,
          type: "success"
        }
        commit(
          `status/${UPDATE_SPINNER}`,
          { is_loading: false },
          { root: true }
        )
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      } catch (error) {
        commit(
          `status/${UPDATE_SPINNER}`,
          { is_loading: false },
          { root: true }
        )
        const status = errorStatus(error)
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      }
    },
    async resetPassword({ commit }, payload) {
      try {
        commit(`status/${UPDATE_SPINNER}`, { is_loading: true }, { root: true })
        const response = await axios.post(`/api/reset-password`, payload)
        const status = {
          message: response.data.message,
          type: "success"
        }
        commit(
          `status/${UPDATE_SPINNER}`,
          { is_loading: false },
          { root: true }
        )
        router.push("/login")
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      } catch (error) {
        commit(
          `status/${UPDATE_SPINNER}`,
          { is_loading: false },
          { root: true }
        )
        const status = errorStatus(error)
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      }
    }
  }
}
