import { library } from "@fortawesome/fontawesome-svg-core"

import { faThumbsUp, faThumbsDown } from "@fortawesome/free-regular-svg-icons"
import {
  faHeart,
  faThumbsUp as fasThumbsUp
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"

export default {
  install(app) {
    library.add(faThumbsUp, faThumbsDown, faHeart, fasThumbsUp)
    app.component("font-awesome-icon", FontAwesomeIcon)
  }
}
