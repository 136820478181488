import axios from "axios"
import {
  UPDATE_STATUS,
  TOGGLE_POST_LIKE,
  TOGGLE_LIKE
} from "../utils/mutation-types"
import { errorStatus } from "../utils/misc"

export default {
  namespaced: true,

  state: {
    likes: []
  },

  actions: {
    async togglePostLike({ commit }, payload) {
      try {
        const response = await axios.post(
          `/api/posts/likes/${payload.id}`,
          payload
        )

        const like = {
          ...response.data,
          post_id: payload.id,
          user_id: payload.user_id
        }

        commit(TOGGLE_LIKE, like)
        commit(`post/${TOGGLE_POST_LIKE}`, like, { root: true })
      } catch (error) {
        const status = errorStatus(error)
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      }
    }
  },

  mutations: {
    [TOGGLE_LIKE](state, payload) {
      if (payload.message === "liked") {
        state.likes.unshift(payload.like)
      } else if (payload.message === "unliked") {
        const likeIndex = state.likes.findIndex(
          (like) => like.id === payload.id
        )
        if (likeIndex !== -1) {
          state.likes.splice(likeIndex, 1)
        }
      }
    }
  }
}
