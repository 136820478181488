import axios from "axios"
import { UPDATE_STATUS, UPDATE_SPINNER } from "../utils/mutation-types"
import { errorStatus } from "../utils/misc"

export default {
  namespaced: true,

  actions: {
    async sendEmailVerificationLink({ commit }) {
      try {
        commit(`status/${UPDATE_SPINNER}`, { is_loading: true }, { root: true })
        const response = await axios.post(
          `/api/email/verification-notification`
        )
        const status = {
          message: response.data.message,
          type: "success"
        }
        commit(
          `status/${UPDATE_SPINNER}`,
          { is_loading: false },
          { root: true }
        )
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      } catch (error) {
        commit(
          `status/${UPDATE_SPINNER}`,
          { is_loading: false },
          { root: true }
        )
        const status = errorStatus(error)
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      }
    },
    async verifyEmail({ commit }, payload) {
      try {
        commit(`status/${UPDATE_SPINNER}`, { is_loading: true }, { root: true })
        const response = await axios.get(
          `/api/email/verify/${payload.userId}/${payload.hash}`
        )
        commit(
          `status/${UPDATE_SPINNER}`,
          { is_loading: false },
          { root: true }
        )
        const status = {
          message: response.data.message,
          type: "success",
          kind: "alert"
        }
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      } catch (error) {
        commit(
          `status/${UPDATE_SPINNER}`,
          { is_loading: false },
          { root: true }
        )
        const status = errorStatus(error)
        status.kind = "alert"
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      }
    }
  }
}
