import { VueCookieNext } from "vue-cookie-next"

export const isAuth = () => {
  return VueCookieNext.isCookieAvailable("token")
}

export const errorStatus = (error) => {
  const respType = typeof error.response !== "undefined"

  const status = {
    message: null,
    type: "error"
  }

  if (respType) {
    const resp = error.response.data
    status.message = resp.error ? resp.error : resp.message
  } else {
    status.message = error.message
  }

  if (status.message === undefined) {
    status.message = error.response?.statusText
  }

  return status
}

export const getCloudinaryPId = (link) => {
  if (!link || !/(http|https)/.test(link)) {
    return ""
  }
  const data = link.split("/").slice(-2)
  return `${data[0]}/${data[1].replace(/\.[^/.]+$/, "")}`
}

export const loadScript = (callback) => {
  let jsScript = document.createElement("script")
  const playerLink =
    "https://unpkg.com/cloudinary-video-player@1.9.9/dist/cld-video-player.min.js"
  jsScript.setAttribute("src", playerLink)
  // Create event listening to run after script is loaded
  if (callback !== undefined) {
    jsScript.addEventListener("load", () => callback())
  }
  document.head.appendChild(jsScript)
}


export const adsScript = (callback) => {
  let jsScript = document.createElement("script")
  const adsLink =
      "https://imasdk.googleapis.com/js/sdkloader/ima3.js"
  jsScript.setAttribute("src", adsLink)

  if (callback !== undefined) {
    jsScript.addEventListener("load", () => callback())
  }
  document.head.appendChild(jsScript)
}