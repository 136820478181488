import { createRouter, createWebHistory } from "vue-router"
import { isAuth } from "../utils/misc"
import { requiresAdmin } from "../utils/router-guards"

// Dynamic imports for lazy-loading
const Home = () => import("@/views/Home.vue")
const Profile = () => import("@/views/Profile.vue")
const Login = () => import("@/views/Login.vue")
const SignUp = () => import("@/views/SignUp.vue")
const ForgotPassword = () => import("@/views/ForgotPassword.vue")
const ResetPassword = () => import("@/views/ResetPassword.vue")
const NewPassword = () => import("@/views/NewPassword.vue")
const LostDocuments = () => import("@/views/LostDocuments.vue")
const AdminPost = () => import("@/views/dashboard/Post.vue")
const AdminUsers = () => import("@/views/dashboard/Users.vue")
const AdminPlaylist = () => import("@/views/dashboard/Playlist.vue")
const NotFound = () => import("@/views/404.vue")

const Legal = () => import("@/views/Legal.vue")
const PrivacyPolicy = () => import("@/views/PrivacyPolicy.vue")
const TermsAndConditions = () => import("@/views/TermsAndConditions.vue")
const CookiePolicy = () => import("@/views/CookiePolicy.vue")
const UserPlaylist = () => import("@/views/UserPlaylist.vue")
const PlaylistStream = () => import("@/views/PlaylistStream.vue")
const Settings = () => import("@/views/Settings.vue")
const Search = () => import("@/views/Search.vue")
const VerifyEmail = () => import("@/views/VerifyEmail.vue")

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: { requiresAuth: false }
  },
  {
    path: "/profile/:id",
    name: "profile",
    component: Profile,
    meta: { requiresAuth: true }
  },
  {
    path: "/settings",
    name: "settings",
    component: Settings,
    meta: { requiresAuth: true }
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: { requiresAuth: false }
  },
  {
    path: "/signup",
    name: "signup",
    component: SignUp,
    meta: { requiresAuth: false }
  },
  {
    path: "/forgot-password",
    name: "forgotPassword",
    component: ForgotPassword,
    meta: { requiresAuth: false }
  },
  {
    path: "/reset-password",
    name: "resetPassword",
    component: ResetPassword,
    meta: { requiresAuth: false }
  },
  {
    path: "/newpassword",
    name: "newPassword",
    component: NewPassword,
    meta: { requiresAuth: false }
  },
  {
    path: "/lostdocuments",
    name: "lostDocuments",
    component: LostDocuments,
    meta: { requiresAuth: true }
  },
  {
    path: "/playlists",
    name: "playlists",
    component: UserPlaylist,
    meta: { requiresAuth: false }
  },
  {
    path: "/playlists/:id",
    name: "playlistStream",
    component: PlaylistStream,
    meta: { requiresAuth: false }
  },
  {
    path: "/dashboard/posts",
    name: "post",
    component: AdminPost,
    meta: { requiresAuth: true },
    beforeEnter: [requiresAdmin]
  },
  {
    path: "/dashboard/users",
    name: "users",
    component: AdminUsers,
    meta: { requiresAuth: true },
    beforeEnter: [requiresAdmin]
  },
  {
    path: "/dashboard/playlist",
    name: "playlist",
    component: AdminPlaylist,
    meta: { requiresAuth: true },
    beforeEnter: [requiresAdmin]
  },
  {
    path: "/:pathMatch(.*)*",
    name: "notfound",
    component: NotFound,
    meta: { requiresAuth: false }
  },
  {
    path: "/legal",
    component: Legal,
    meta: { requiresAuth: false }
  },
  {
    path: "/legal/cookies",
    name: "cookiePolicy",
    component: CookiePolicy,
    meta: { requiresAuth: false }
  },
  {
    path: "/legal/privacy",
    name: "privacyPolicy",
    component: PrivacyPolicy,
    meta: { requiresAuth: false }
  },
  {
    path: "/legal/terms-of-service",
    name: "termsAndConditions",
    component: TermsAndConditions,
    meta: { requiresAuth: false }
  },
  {
    path: "/search",
    name: "search",
    component: Search,
    meta: { requiresAuth: false }
  },
  {
    path: "/email/verify/:id/:hash",
    name: "verifyEmail",
    component: VerifyEmail,
    meta: { requiresAuth: true }
  }
]

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: "active-link",
  routes
})

router.beforeEach((to) => {
  if (to.meta.requiresAuth && !isAuth()) {
    return {
      path: "/login",
      // save the location we were at to come back later
      query: { redirect: to.fullPath }
    }
  }
})

export default router
