<template>
  <div class="dropdown">
    <div class="input-group" aria-expanded="false" data-bs-toggle="dropdown">
      <label class="input-group-text">
        <i class="bi bi-search fs-6"></i>
      </label>
      <input
        id="searchDropdown"
        ref="searchDropdown"
        type="text"
        class="form-control border-start-0"
        :class="{ 'border-end-0': searchTerm }"
        placeholder="Start typing to search..."
        v-model="searchTerm"
      />
      <label class="input-group-text" v-if="searchTerm">
        <i class="bi bi-x fs-6" @click="clearSearch"></i>
      </label>
    </div>
    <div class="dropdown-menu w-100 px-4" aria-labelledby="searchDropdown">
      <div
        v-for="result in results"
        :key="result.id"
        class="dropdown-item"
        @click="selectResult(result)"
      >
        {{ result.title }}
      </div>

      <div v-if="!searchTerm || results.length == 0">
        No results found for search
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchTerm: "",
      results: []
    }
  },

  methods: {
    selectResult(result) {
      this.searchTerm = ""
      this.$emit("result-selected", result)
    },
    clearSearch() {
      this.searchTerm = ""
    }
  }
}
</script>

<style scoped lang="scss">
@media (max-width: 991.98px) {
  .input-group {
    width: 18rem;
  }
}

@media (min-width: 992px) {
  .input-group {
    width: 22rem;
  }
}
</style>
