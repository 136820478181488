import { UPDATE_STATUS, UPDATE_SPINNER } from "../utils/mutation-types"

export default {
  namespaced: true,

  state: {
    value: false,
    status_type: null,
    message: null,
    is_loading: false
  },

  mutations: {
    [UPDATE_STATUS](state, payload) {
      state.value = payload.value == undefined ? true : payload.value
      state.status_type = payload.type
      state.message = payload.message
    },
    [UPDATE_SPINNER](state, payload) {
      state.is_loading = payload.is_loading
    }
  }
}
