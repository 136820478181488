<template>
  <div
    class="position-fixed top-50 start-50 translate-middle p-3"
    style="z-index: 99"
  >
    <div
      :class="'toast align-items-center text-white border-0 ' + notif_color"
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
      ref="notification"
    >
      <div class="d-flex">
        <div class="toast-body">
          <i class="bi bi-bell-fill"></i>
          {{ message }}
        </div>
        <button
          type="button"
          class="btn-close btn-close-white me-2 m-auto"
          data-bs-dismiss="toast"
          aria-label="Close"
        ></button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex"
import { UPDATE_STATUS } from "@/utils/mutation-types"
import { Toast } from "bootstrap"

export default {
  name: "TheNotification",
  computed: {
    ...mapState("status", ["value", "message", "status_type"]),
    notif_color() {
      const color = this.status_type == "success" ? "bg-success" : "bg-danger"
      return color
    }
  },
  methods: {
    ...mapMutations("status", { updateStatus: UPDATE_STATUS }),
    showNotification() {
      if (this.value) {
        const toast = new Toast(this.$refs.notification)
        toast.show()
      }
    }
  },
  mounted() {
    const notification = this.$refs.notification
    notification.addEventListener("hide.bs.toast", () => {
      this.updateStatus({ value: false, type: null, message: null })
    })
    this.showNotification()
  },
  updated() {
    this.showNotification()
  }
}
</script>
