import axios from "axios"
import {
  UPDATE_STATUS,
  UPDATE_SPINNER,
  ADD_POSTS,
  ADD_ADMIN_POSTS,
  ADD_POST,
  ADD_USER_POSTS,
  EDIT_POST,
  DELETE_POST,
  EDIT_POST_COMMENT,
  ADD_POST_COMMENT,
  DELETE_POST_COMMENT,
  TOGGLE_POST_LIKE,
  VIEW_POST,
  DELETE_ADMIN_COMMENT,
  SET_POST_STATS,
  DELETE_ADMIN_POST
} from "../utils/mutation-types"

import { errorStatus } from "../utils/misc"

export default {
  namespaced: true,

  state: {
    posts: [],
    admin_posts: [],
    page: {
      current_page: 1,
      last_page: null,
      from: null,
      per_page: 6,
      to: null,
      total: null
    },
    post_stats: null
  },

  actions: {
    async getPosts({ commit }, payload) {
      try {
        commit(`status/${UPDATE_SPINNER}`, { is_loading: true }, { root: true })
        const response = await axios.get(
          `/api/posts?page=${payload.page}&per_page=${payload.per_page}`
        )
        if (payload.section == "admin") {
          commit(ADD_ADMIN_POSTS, response.data)
        } else {
          commit(ADD_POSTS, response.data)
        }
        commit(
          `status/${UPDATE_SPINNER}`,
          { is_loading: false },
          { root: true }
        )
      } catch (error) {
        commit(
          `status/${UPDATE_SPINNER}`,
          { is_loading: false },
          { root: true }
        )
        const status = errorStatus(error)
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      }
    },
    async getUserPosts({ commit }, payload) {
      try {
        commit(`status/${UPDATE_SPINNER}`, { is_loading: true }, { root: true })
        const response = await axios.get(
          `/api/users/${payload.userId}/posts?page=${payload.page}`
        )
        commit(ADD_USER_POSTS, response.data.posts)
        commit(
          `status/${UPDATE_SPINNER}`,
          { is_loading: false },
          { root: true }
        )
      } catch (error) {
        commit(
          `status/${UPDATE_SPINNER}`,
          { is_loading: false },
          { root: true }
        )
        const status = errorStatus(error)
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      }
    },
    async createPost({ commit }, payload) {
      try {
        commit(`status/${UPDATE_SPINNER}`, { is_loading: true }, { root: true })
        const response = await axios.post(`/api/posts`, payload, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        commit(
          `status/${UPDATE_SPINNER}`,
          { is_loading: false },
          { root: true }
        )
        const status = {
          message: response.data.message,
          type: "success"
        }
        commit(ADD_POST, response.data.post)
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      } catch (error) {
        commit(
          `status/${UPDATE_SPINNER}`,
          { is_loading: false },
          { root: true }
        )
        const status = errorStatus(error)
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      }
    },
    async editPost({ commit }, payload) {
      try {
        commit(`status/${UPDATE_SPINNER}`, { is_loading: true }, { root: true })
        const response = await axios.post(
          `/api/posts/${payload.get("id")}`,
          payload,
          {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          }
        )
        commit(
          `status/${UPDATE_SPINNER}`,
          { is_loading: false },
          { root: true }
        )
        const status = {
          message: response.data.message,
          type: "success"
        }
        commit(EDIT_POST, response.data.post)
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      } catch (error) {
        commit(
          `status/${UPDATE_SPINNER}`,
          { is_loading: false },
          { root: true }
        )
        const status = errorStatus(error)
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      }
    },
    async deletePost({ commit }, payload) {
      try {
        commit(`status/${UPDATE_SPINNER}`, { is_loading: true }, { root: true })
        const url = payload.section == "admin" ? `/api/admin` : `/api`
        const response = await axios.delete(`${url}/posts/${payload.id}`)

        commit(
          `status/${UPDATE_SPINNER}`,
          { is_loading: false },
          { root: true }
        )
        const status = {
          message: response.data.message,
          type: "success"
        }
        if (payload.section == "admin") {
          commit(DELETE_ADMIN_POST, payload)
        } else {
          commit(DELETE_POST, payload)
        }
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      } catch (error) {
        commit(
          `status/${UPDATE_SPINNER}`,
          { is_loading: false },
          { root: true }
        )
        const status = errorStatus(error)
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      }
    },
    async reportPost({ commit }, payload) {
      try {
        commit(`status/${UPDATE_SPINNER}`, { is_loading: true }, { root: true })
        const response = await axios.post(
          `/api/posts/${payload.post_id}/reports`,
          payload
        )
        commit(
          `status/${UPDATE_SPINNER}`,
          { is_loading: false },
          { root: true }
        )
        const status = {
          message: response.data.message,
          type: "success"
        }
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      } catch (error) {
        commit(
          `status/${UPDATE_SPINNER}`,
          { is_loading: false },
          { root: true }
        )
        const status = errorStatus(error)
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      }
    },
    async deleteReport({ commit }, payload) {
      try {
        commit(`status/${UPDATE_SPINNER}`, { is_loading: true }, { root: true })
        const response = await axios.delete(`/api/report/${payload.id}`)
        commit(
          `status/${UPDATE_SPINNER}`,
          { is_loading: false },
          { root: true }
        )
        const status = {
          message: response.data.message,
          type: "success"
        }
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      } catch (error) {
        commit(
          `status/${UPDATE_SPINNER}`,
          { is_loading: false },
          { root: true }
        )
        const status = errorStatus(error)
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      }
    },
    async viewPost({ commit }, payload) {
      try {
        const response = await axios.get(`/api/posts/view/${payload.id}`)
        commit(EDIT_POST, response.data)
      } catch (error) {
        const status = errorStatus(error)
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      }
    },
    async getPostStats({ commit }) {
      try {
        const response = await axios.get(`/api/admin/post_count`)
        commit(SET_POST_STATS, response.data)
      } catch (error) {
        const status = errorStatus(error)
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      }
    }
  },

  mutations: {
    [ADD_POST](state, payload) {
      state.posts.unshift(payload)
    },
    [EDIT_POST](state, payload) {
      state.posts = state.posts.map((post) => {
        if (post.id === payload.id) {
          post = payload
        }
        return post
      })
    },
    [DELETE_POST](state, payload) {
      const postIndex = state.posts.findIndex((post) => post.id === payload.id)
      if (postIndex !== -1) {
        state.posts.splice(postIndex, 1)
      }
    },
    [DELETE_ADMIN_POST](state, payload) {
      const postIndex = state.admin_posts.findIndex(
        (post) => post.id === payload.id
      )
      if (postIndex !== -1) {
        state.admin_posts.splice(postIndex, 1)
      }
    },
    [ADD_POSTS](state, payload) {
      const current_page = payload.meta.current_page
      if (current_page === 1) {
        state.posts = payload.data
      } else {
        state.posts = state.posts.concat(payload.data)
      }
      state.page = {
        current_page,
        last_page: payload.meta.last_page,
        from: payload.meta.from,
        per_page: payload.meta.per_page,
        to: payload.meta.to,
        total: payload.meta.total
      }
    },
    [ADD_USER_POSTS](state, payload) {
      const current_page = payload.current_page
      if (current_page === 1) {
        state.posts = payload.data
      } else {
        state.posts = state.posts.concat(payload.data)
      }
      state.page = {
        current_page,
        last_page: payload.last_page,
        from: payload.from,
        per_page: payload.per_page,
        to: payload.to,
        total: payload.total
      }
    },
    [ADD_ADMIN_POSTS](state, payload) {
      state.admin_posts = payload.data
      const meta = payload.meta
      state.page = {
        current_page: meta.current_page,
        last_page: meta.last_page,
        from: meta.from,
        per_page: meta.per_page,
        to: meta.to,
        total: meta.total
      }
    },
    [ADD_POST_COMMENT](state, payload) {
      state.posts = state.posts.map((post) => {
        if (post.id === payload.post_id) {
          post.comments.unshift(payload)
        }
        return post
      })
    },
    [EDIT_POST_COMMENT](state, payload) {
      const postIndex = state.posts.findIndex(
        (post) => post.id === payload.post_id
      )
      if (postIndex === -1) {
        return
      }

      state.posts[postIndex].comments = state.posts[postIndex].comments.map(
        (comment) => {
          if (comment.id === payload.id) {
            comment = payload
          }
          return comment
        }
      )
    },
    [DELETE_POST_COMMENT](state, payload) {
      const postIndex = state.posts.findIndex(
        (post) => post.id === payload.post_id
      )
      if (postIndex === -1) {
        return
      }

      const commentIndex = state.posts[postIndex].comments.findIndex(
        (comment) => comment.id === payload.comment_id
      )
      if (commentIndex !== -1) {
        state.posts[postIndex].comments.splice(commentIndex, 1)
      }
    },
    [DELETE_ADMIN_COMMENT](state, payload) {
      const postIndex = state.admin_posts.findIndex(
        (post) => post.id === payload.post_id
      )
      if (postIndex === -1) {
        return
      }

      const commentIndex = state.admin_posts[postIndex].comments.findIndex(
        (comment) => comment.id === payload.comment_id
      )
      if (commentIndex !== -1) {
        state.admin_posts[postIndex].comments.splice(commentIndex, 1)
      }
    },
    [TOGGLE_POST_LIKE](state, payload) {
      if (payload.message === "liked") {
        state.posts = state.posts.map((post) => {
          if (post.id === payload.like.post_id) {
            post.likes.unshift(payload.like)
          }
          return post
        })
        return
      }

      const postIndex = state.posts.findIndex(
        (post) => post.id === payload.post_id
      )
      if (postIndex === -1) {
        return
      }

      const likeIndex = state.posts[postIndex].likes.findIndex(
        (like) => like.user_id == payload.user_id
      )
      if (likeIndex !== -1) {
        state.posts[postIndex].likes.splice(likeIndex, 1)
      }
    },
    [VIEW_POST](state, payload) {
      state.posts = state.posts.map((post) => {
        if (post.id === payload.view.post_id) {
          post.views.unshift(payload.view)
        }
        return post
      })
    },
    [SET_POST_STATS](state, payload) {
      state.post_stats = payload
    }
  }
}
