import axios from "axios"
import {
  UPDATE_STATUS,
  UPDATE_SPINNER,
  SET_USER,
  SET_PROFILE,
  ADD_USERS,
  DELETE_USER,
  SET_USER_STATS,
  ADD_VERIFIED_USERS
} from "../utils/mutation-types"
import { errorStatus } from "../utils/misc"

export default {
  namespaced: true,

  state: {
    users: [],
    user: null, // User we are viewing
    page: {
      current_page: 1,
      last_page: null,
      from: null,
      per_page: 6,
      to: null,
      total: null
    },
    user_stats: null,
    profile: null // Profile of logged in user
  },

  actions: {
    async getUsers({ commit }, payload) {
      try {
        commit(`status/${UPDATE_SPINNER}`, { is_loading: true }, { root: true })
        const response = await axios.get(
          `/api/users?page=${payload.page}&per_page=${payload.per_page}`
        )
        commit(ADD_USERS, response.data)
        commit(
          `status/${UPDATE_SPINNER}`,
          { is_loading: false },
          { root: true }
        )
      } catch (error) {
        commit(
          `status/${UPDATE_SPINNER}`,
          { is_loading: false },
          { root: true }
        )
        const status = errorStatus(error)
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      }
    },
    async getCurrentUser({ commit }) {
      try {
        const response = await axios.get(`/api/user`)
        commit(SET_USER, response.data)
      } catch (error) {
        const status = errorStatus(error)
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      }
    },
    async getUser({ commit }, userId) {
      try {
        const response = await axios.get(`/api/users/${userId}`)
        commit(SET_PROFILE, response.data)
      } catch (error) {
        const status = errorStatus(error)
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      }
    },
    async editUser({ commit }, payload) {
      try {
        const response = await axios.post(`/api/users/${payload.id}`, payload)
        const status = {
          message: response.data.message,
          type: "success"
        }
        commit(SET_USER, response.data.user)
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      } catch (error) {
        const status = errorStatus(error)
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      }
    },
    async deleteUser({ commit }, payload) {
      try {
        commit(`status/${UPDATE_SPINNER}`, { is_loading: true }, { root: true })
        const response = await axios.delete(`/api/admin/users/${payload.id}`)
        commit(
          `status/${UPDATE_SPINNER}`,
          { is_loading: false },
          { root: true }
        )
        const status = {
          message: response.data.message,
          type: "success"
        }
        commit(DELETE_USER, payload)
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      } catch (error) {
        commit(
          `status/${UPDATE_SPINNER}`,
          { is_loading: false },
          { root: true }
        )
        const status = errorStatus(error)
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      }
    },
    async getUserStats({ commit }) {
      try {
        const response = await axios.get(`/api/admin/user_count`)
        commit(SET_USER_STATS, response.data)
      } catch (error) {
        const status = errorStatus(error)
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      }
    },
    async uploadProfileImage({ commit }, payload) {
      try {
        commit(`status/${UPDATE_SPINNER}`, { is_loading: true }, { root: true })
        let response = null
        if (payload.type == "avatar") {
          response = await axios.post(
            `/api/users/${payload.userId}/update_photo`,
            payload.data,
            {
              headers: {
                "Content-Type": "multipart/form-data"
              }
            }
          )
        } else {
          response = await axios.post(
            `/api/users/${payload.userId}/cover_photo`,
            payload.data,
            {
              headers: {
                "Content-Type": "multipart/form-data"
              }
            }
          )
        }
        commit(
          `status/${UPDATE_SPINNER}`,
          { is_loading: false },
          { root: true }
        )
        const status = {
          message: response.data.message,
          type: "success"
        }
        commit(SET_USER, response.data.user)
        commit(SET_PROFILE, response.data.user)
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      } catch (error) {
        commit(
          `status/${UPDATE_SPINNER}`,
          { is_loading: false },
          { root: true }
        )
        const status = errorStatus(error)
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      }
    },
    async getVerifiedUsers({ commit }, payload) {
      try {
        commit(`status/${UPDATE_SPINNER}`, { is_loading: true }, { root: true })
        const response = await axios.get(
          `/api/admin/verified/users?page=${payload.page}&per_page=${payload.per_page}`
        )
        commit(ADD_VERIFIED_USERS, response.data)
        commit(
          `status/${UPDATE_SPINNER}`,
          { is_loading: false },
          { root: true }
        )
      } catch (error) {
        commit(
          `status/${UPDATE_SPINNER}`,
          { is_loading: false },
          { root: true }
        )
        const status = errorStatus(error)
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      }
    },
  },

  mutations: {
    [SET_USER](state, payload) {
      state.user = payload
    },
    [SET_PROFILE](state, payload) {
      state.profile = payload
    },
    [ADD_USERS](state, payload) {
      state.users = payload.data
      const meta = payload.meta
      state.page = {
        current_page: meta.current_page,
        last_page: meta.last_page,
        from: meta.from,
        per_page: meta.per_page,
        to: meta.to,
        total: meta.total
      }
    },
    [DELETE_USER](state, payload) {
      const userIndex = state.users.findIndex((user) => user.id === payload.id)
      if (userIndex !== -1) {
        state.users.splice(userIndex, 1)
      }
    },
    [SET_USER_STATS](state, payload) {
      state.user_stats = payload
    },
    [ADD_VERIFIED_USERS](state, payload) {
      state.users = payload.data
      const meta = payload.meta
      state.page = {
        current_page: meta.current_page,
        last_page: meta.last_page,
        from: meta.from,
        per_page: meta.per_page,
        to: meta.to,
        total: meta.total
      }
    },
  }
}
