import axios from "axios"
import {
  ADD_PLAYLIST,
  EDIT_PLAYLIST,
  DELETE_PLAYLIST,
  ADD_PLAYLISTS,
  SET_PLAYLIST,
  UPDATE_SPINNER,
  UPDATE_STATUS,
  ADD_PLAYLIST_TRACK,
  EDIT_PLAYLIST_TRACK,
  DELETE_PLAYLIST_TRACK,
  ADD_USER_PLAYLISTS,
  ADD_SEARCH_PLAYLISTS,
  ADD_SEARCH_USER_PLAYLISTS
} from "../utils/mutation-types"

import { errorStatus } from "../utils/misc"

export default {
  namespaced: true,

  state: {
    playlists: [],
    page: {
      current_page: 1,
      last_page: null,
      from: null,
      to: null,
      total: null
    },
    playlist: null
  },
  actions: {
    async getPlaylists({ commit }, payload) {
      try {
        commit(`status/${UPDATE_SPINNER}`, { is_loading: true }, { root: true })
        if (payload.search == "") {
          const response = await axios.get(`/api/playlist?page=${payload.page}`)
          if (payload.section == "admin") {
            commit(ADD_PLAYLISTS, response.data)
          } else {
            commit(ADD_USER_PLAYLISTS, response.data)
          }
        } else {
          const response = await axios.get(
            `/api/search/playlists?page=${payload.page}&search=${payload.search}`
          )

          if (payload.section == "admin") {
            commit(ADD_SEARCH_PLAYLISTS, response.data.playlists)
          } else {
            commit(ADD_SEARCH_USER_PLAYLISTS, response.data.playlists)
          }
        }
        commit(
          `status/${UPDATE_SPINNER}`,
          { is_loading: false },
          { root: true }
        )
      } catch (error) {
        commit(
          `status/${UPDATE_SPINNER}`,
          { is_loading: false },
          { root: true }
        )
        const status = errorStatus(error)
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      }
    },
    async getPlaylist({ commit }, payload) {
      try {
        commit(`status/${UPDATE_SPINNER}`, { is_loading: true }, { root: true })
        const response = await axios.get(`/api/playlist/${payload.id}`)
        commit(SET_PLAYLIST, response.data)

        commit(
          `status/${UPDATE_SPINNER}`,
          { is_loading: false },
          { root: true }
        )
      } catch (error) {
        commit(
          `status/${UPDATE_SPINNER}`,
          { is_loading: false },
          { root: true }
        )
        const status = errorStatus(error)
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      }
    },
    async createPlaylist({ commit }, payload) {
      try {
        commit(`status/${UPDATE_SPINNER}`, { is_loading: true }, { root: true })
        const response = await axios.post(`/api/playlist`, payload, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        commit(
          `status/${UPDATE_SPINNER}`,
          { is_loading: false },
          { root: true }
        )
        const status = {
          message: response.data.message,
          type: "success"
        }
        commit(ADD_PLAYLIST, response.data.playlist)
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      } catch (error) {
        commit(
          `status/${UPDATE_SPINNER}`,
          { is_loading: false },
          { root: true }
        )
        const status = errorStatus(error)
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      }
    },
    async editPlaylist({ commit }, payload) {
      try {
        commit(`status/${UPDATE_SPINNER}`, { is_loading: true }, { root: true })
        const response = await axios.put(
          `/api/playlist/${payload.get("id")}`,
          payload
        )
        commit(
          `status/${UPDATE_SPINNER}`,
          { is_loading: false },
          { root: true }
        )
        const status = {
          message: response.data.message,
          type: "success"
        }
        commit(EDIT_PLAYLIST, response.data.playlist)
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      } catch (error) {
        commit(
          `status/${UPDATE_SPINNER}`,
          { is_loading: false },
          { root: true }
        )
        const status = errorStatus(error)
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      }
    },
    async deletePlaylist({ commit }, payload) {
      try {
        commit(`status/${UPDATE_SPINNER}`, { is_loading: true }, { root: true })
        const response = await axios.delete(`/api/playlist/${payload.id}`)

        commit(
          `status/${UPDATE_SPINNER}`,
          { is_loading: false },
          { root: true }
        )
        const status = {
          message: response.data.message,
          type: "success"
        }
        commit(DELETE_PLAYLIST, payload)
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      } catch (error) {
        commit(
          `status/${UPDATE_SPINNER}`,
          { is_loading: false },
          { root: true }
        )
        const status = errorStatus(error)
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      }
    }
  },
  mutations: {
    [ADD_PLAYLIST](state, payload) {
      state.playlists.unshift(payload)
    },
    [EDIT_PLAYLIST](state, payload) {
      state.playlists = state.playlists.map((playlist) => {
        if (playlist.id === payload.id) {
          playlist = payload
        }
        return playlist
      })
    },
    [DELETE_PLAYLIST](state, payload) {
      const playlistIndex = state.playlists.findIndex(
        (playlist) => playlist.id === payload.id
      )
      if (playlistIndex !== -1) {
        state.playlists.splice(playlistIndex, 1)
      }
    },
    [SET_PLAYLIST](state, payload) {
      state.playlist = payload
    },
    [ADD_USER_PLAYLISTS](state, payload) {
      const current_page = payload.meta.current_page
      if (current_page === 1) {
        state.playlists = payload.data
      } else {
        state.playlists = state.playlists.concat(payload.data)
      }
      state.page = {
        current_page,
        last_page: payload.meta.last_page,
        from: payload.meta.from,
        to: payload.meta.to,
        total: payload.meta.total
      }
    },
    [ADD_SEARCH_USER_PLAYLISTS](state, payload) {
      const current_page = payload.current_page
      if (current_page === 1) {
        state.playlists = payload.data
      } else {
        state.playlists = state.playlists.concat(payload.data)
      }
      state.page = {
        current_page,
        last_page: payload.last_page,
        from: payload.from,
        to: payload.to,
        total: payload.total
      }
    },
    [ADD_PLAYLISTS](state, payload) {
      state.playlists = payload.data
      const meta = payload.meta
      state.page = {
        current_page: meta.current_page,
        last_page: meta.last_page,
        from: meta.from,
        to: meta.to,
        total: meta.total
      }
    },
    [ADD_SEARCH_PLAYLISTS](state, payload) {
      state.playlists = payload.data
      state.page = {
        current_page: payload.current_page,
        last_page: payload.last_page,
        from: payload.from,
        to: payload.to,
        total: payload.total
      }
    },
    [ADD_PLAYLIST_TRACK](state, payload) {
      state.playlists = state.playlists.map((playlist) => {
        if (playlist.id === payload.playlist_id) {
          playlist.tracks.unshift(payload)
        }
        return playlist
      })

      // Add track to track screen
      if (state.playlist && state.playlist.id == payload.playlist_id) {
        state.playlist.tracks.unshift(payload)
      }
    },
    [EDIT_PLAYLIST_TRACK](state, payload) {
      const playlistIndex = state.playlists.findIndex(
        (playlist) => playlist.id === payload.playlist_id
      )
      if (playlistIndex !== -1) {
        state.playlists[playlistIndex].tracks = state.playlists[
          playlistIndex
        ].tracks.map((track) => {
          if (track.id === payload.id) {
            track = payload
          }
          return track
        })
      }

      // Edit track on track screen
      if (state.playlist && state.playlist.id == payload.playlist_id) {
        state.playlist.tracks = state.playlist.tracks.map((track) => {
          if (track.id === payload.id) {
            track = payload
          }
          return track
        })
      }
    },
    [DELETE_PLAYLIST_TRACK](state, payload) {
      const playlistIndex = state.playlists.findIndex(
        (playlist) => playlist.id === payload.playlist_id
      )

      if (playlistIndex !== -1) {
        const trackIndex = state.playlists[playlistIndex].tracks.findIndex(
          (track) => track.id === payload.track_id
        )
        if (trackIndex !== -1) {
          state.playlists[playlistIndex].tracks.splice(trackIndex, 1)
        }
      }

      // Delete track from track screen
      if (state.playlist && state.playlist.id == payload.playlist_id) {
        const trackIndex2 = state.playlist.tracks.findIndex(
          (track) => track.id === payload.track_id
        )
        if (trackIndex2 !== -1) {
          state.playlist.tracks.splice(trackIndex2, 1)
        }
      }
    }
  },
  getters: {
    getNextTrack(state, getters, rootState) {
      const trackIndex = state.playlist.tracks.findIndex(
        (track) => track.id === rootState.track.track.id
      )

      if (trackIndex === -1) {
        return null
      }
      if (trackIndex < state.playlist.tracks.length - 1) {
        return state.playlist.tracks[trackIndex + 1]
      }
      return null
    }
  }
}
