import { Cloudinary } from "@cloudinary/url-gen"

export default {
  install(app) {
    app.config.globalProperties.cld = new Cloudinary({
      cloud: { cloudName: process.env.VUE_APP_CLOUDINARY_CLOUD_NAME },
      url: { secure: true }
    })
  }
}
