import axios from "axios"
import {
  UPDATE_STATUS,
  UPDATE_SPINNER,
  ADD_COMMENT,
  ADD_COMMENTS,
  EDIT_COMMENT,
  DELETE_COMMENT,
  ADD_POST_COMMENT,
  EDIT_POST_COMMENT,
  DELETE_POST_COMMENT,
  DELETE_ADMIN_COMMENT
} from "../utils/mutation-types"
import { errorStatus } from "../utils/misc"

export default {
  namespaced: true,

  state: {
    comments: []
  },

  actions: {
    async getComments({ commit }) {
      try {
        commit(`status/${UPDATE_SPINNER}`, { is_loading: true }, { root: true })
        const response = await axios.get(`/api/comments`)
        commit(ADD_COMMENTS, response.data.data)
        commit(
          `status/${UPDATE_SPINNER}`,
          { is_loading: false },
          { root: true }
        )
      } catch (error) {
        commit(
          `status/${UPDATE_SPINNER}`,
          { is_loading: false },
          { root: true }
        )
        const status = errorStatus(error)
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      }
    },
    async createComment({ commit }, payload) {
      try {
        const response = await axios.post(
          `/api/posts/${payload.post_id}/comments`,
          payload.comment
        )
        const status = {
          message: response.data.message,
          type: "success"
        }
        const comment = response.data.data
        commit(ADD_COMMENT, comment)
        commit(`post/${ADD_POST_COMMENT}`, comment, { root: true })
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      } catch (error) {
        const status = errorStatus(error)
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      }
    },
    async editComment({ commit }, payload) {
      try {
        const response = await axios.put(`/api/comments/${payload.id}`, payload)
        const status = {
          message: response.data.message,
          type: "success"
        }
        const comment = response.data.comment
        commit(EDIT_COMMENT, comment)
        commit(`post/${EDIT_POST_COMMENT}`, comment, { root: true })
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      } catch (error) {
        const status = errorStatus(error)
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      }
    },
    async deleteComment({ commit }, payload) {
      try {
        const response = await axios.delete(
          `/api/comments/${payload.comment_id}`
        )
        const status = {
          message: response.data.message,
          type: "success"
        }
        commit(DELETE_COMMENT, payload)
        if (payload.section == "admin") {
          commit(`post/${DELETE_ADMIN_COMMENT}`, payload, { root: true })
        } else {
          commit(`post/${DELETE_POST_COMMENT}`, payload, { root: true })
        }
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      } catch (error) {
        const status = errorStatus(error)
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      }
    }
  },

  mutations: {
    [ADD_COMMENT](state, payload) {
      state.comments.unshift(payload)
    },
    [EDIT_COMMENT](state, payload) {
      state.comments = state.comments.map((comment) => {
        if (comment.id === payload.id) {
          comment = payload
        }
        return comment
      })
    },
    [DELETE_COMMENT](state, payload) {
      const commentIndex = state.comments.findIndex(
        (comment) => comment.id === payload.id
      )
      if (commentIndex !== -1) {
        state.comments.splice(commentIndex, 1)
      }
    },
    [ADD_COMMENTS](state, payload) {
      state.comments = payload
    }
  }
}
