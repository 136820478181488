import axios from "axios"
import {
  UPDATE_STATUS,
  UPDATE_SPINNER,
  ADD_PHOTOS,
  ADD_PHOTO,
  DELETE_PHOTO
} from "../utils/mutation-types"
import { errorStatus } from "../utils/misc"

export default {
  namespaced: true,
  state: {
    gallery: [],
    page: {
      current_page: 1,
      last_page: null,
      from: null,
      to: null,
      total: null
    }
  },
  actions: {
    async getUserPhotos({ commit }, payload) {
      try {
        commit(`status/${UPDATE_SPINNER}`, { is_loading: true }, { root: true })
        const response = await axios.get(
          `api/users/${payload.userId}/photos?page=${payload.page}`
        )
        commit(ADD_PHOTOS, response.data.photos)
        commit(
          `status/${UPDATE_SPINNER}`,
          { is_loading: false },
          { root: true }
        )
      } catch (error) {
        commit(
          `status/${UPDATE_SPINNER}`,
          { is_loading: false },
          { root: true }
        )
        const status = errorStatus(error)
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      }
    },
    async uploadPhoto({ commit }, payload) {
      try {
        commit(`status/${UPDATE_SPINNER}`, { is_loading: true }, { root: true })
        const response = await axios.post(`/api/photos`, payload, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        commit(
          `status/${UPDATE_SPINNER}`,
          { is_loading: false },
          { root: true }
        )
        const status = {
          message: response.data.message,
          type: "success"
        }
        commit(ADD_PHOTO, response.data.photo)
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      } catch (error) {
        commit(
          `status/${UPDATE_SPINNER}`,
          { is_loading: false },
          { root: true }
        )
        const status = errorStatus(error)
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      }
    },
    async deletePhoto({ commit }, payload) {
      try {
        commit(`status/${UPDATE_SPINNER}`, { is_loading: true }, { root: true })

        const response = await axios.delete(`/api/photos/${payload.id}`)

        commit(
          `status/${UPDATE_SPINNER}`,
          { is_loading: false },
          { root: true }
        )
        const status = {
          message: response.data.message,
          type: "success"
        }
        commit(DELETE_PHOTO, payload)
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      } catch (error) {
        commit(
          `status/${UPDATE_SPINNER}`,
          { is_loading: false },
          { root: true }
        )
        const status = errorStatus(error)
        commit(`status/${UPDATE_STATUS}`, status, { root: true })
      }
    }
  },
  mutations: {
    [ADD_PHOTOS](state, payload) {
      const current_page = payload.current_page
      if (current_page === 1) {
        state.gallery = payload.data
      } else {
        state.gallery = state.gallery.concat(payload.data)
      }
      state.page = {
        current_page,
        last_page: payload.last_page,
        from: payload.from,
        to: payload.to,
        total: payload.total
      }
    },
    [ADD_PHOTO](state, payload) {
      state.gallery.unshift(payload)
    },
    [DELETE_PHOTO](state, payload) {
      const photoIndex = state.gallery.findIndex(
        (photo) => photo.id === payload.id
      )
      if (photoIndex !== -1) {
        state.gallery.splice(photoIndex, 1)
      }
    }
  }
}
